console.log('iii player')
import {
  THREE,
  game,
  cameraManager,
  errorManager,
  playersManager,
  CameraStates,
  modes,
  PlayerSex,
  HairColorTypes,
  gsap,
  timeManager
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig,
  tutorialConfig,
  lungeConfig
} from '../../../config'
import {
  BlueBoxTextType,
  DisciplinePhases,
  LungeStates,
  ModelsNames,
  TutorialEventType
} from '../../../types'
import { Athlete } from '..'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { opponentsManager } from '../opponent/OpponentsManager'
import { trainingTasks } from '@/app/modes/training'
import { PlayerNotesManager } from './PlayerNotesManager'
import {
  gamePhaseState,
  inputsState,
  uiState,
  tutorialState,
  lapPositionState,
  textMessageState
} from '@/stores'

/**
 * Trieda pre hraca
 */
export class Player extends Athlete {

  /** Ci je dokoncena transformacia kamery v speed locku */
  public speedLockCameraTransformationComplete = false

  /** stara player position pred resetom */
  private oldPlayerPos: {[key in CameraStates]: THREE.Vector3 | undefined} = {
    [CameraStates.intro]: undefined,
    [CameraStates.intro2]: undefined,
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.disciplineIntro]: undefined,
    [CameraStates.disciplineOutro]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.static]: undefined,
    [CameraStates.table]: undefined,
  }

  /** manager vizualizacie padlovania */
  public playerNotesManager = new PlayerNotesManager()

  /** Helper objekt pre bottom hud smooth */
  public helperObject = new THREE.Object3D()
  public helperObjectPos?: THREE.Vector3

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const meshAthleteName = modelsConfig[ModelsNames.athlete]?.mainMeshNames?.[0]
    if (!meshAthleteName) {

      throw new Error(errorManager.showBox('Mesh name for athlete was not defined'))

    }

    return game.getObject3D(meshAthleteName)

  }

  /**
   * Vytvorenie lyziara
   * @param trackIndex - Index drahy
   */
  public create(trackIndex: number): void {

    console.log('vytvaram hraca...')

    this.uuid = playersManager.getPlayer().uuid

    let trackIndexEdited = trackIndex
    if (modes.isTrainingMode() || modes.isTutorial()) trackIndexEdited = 5


    this.prefixSex = playersManager.getPlayer().sex === PlayerSex.male ? '' : 'f_'

    super.create(trackIndexEdited, 'Player', ModelsNames.athlete)

    this.setHair()
    game.scene.add(this.helperObject)

    if (!modes.isTutorial()) return
    this.worldEnvLinesManager.generateLungePosition(tutorialConfig.autoLungeMeters)


  }

  /**
   * Nastavenie vlasov
   */
  private setHair(): void {

    this.hair = this.athleteObject.getObjectByName(`${this.prefixSex}hair_low`) as THREE.Mesh
    this.hairTail = this.athleteObject.getObjectByName(`${this.prefixSex}tail_low`) as THREE.Mesh
    console.log(
      'HAIRRRRRS', this.hair, this.hairTail, this.prefixSex, `${this.prefixSex}hair_low`,
      `${this.prefixSex}tail_low`,
      this.athleteObject
    )

    const athlete = playersManager.getPlayerById(this.uuid)
    if (!athlete) return

    const maxCountHairTypes = 3
    let hairTypesVisible = [1, 3, 6, 7]
    let hairTailTypesVisible = [3]

    if (athlete.sex === PlayerSex.female) {

      hairTypesVisible = [1, 2, 3, 5, 6, 7]
      hairTailTypesVisible = [2, 3, 5, 7]

    }

    this.hairType = athlete.hair ?? Math.floor(Math.random() * maxCountHairTypes) + 1
    if (this.hairType > maxCountHairTypes) this.hairType = maxCountHairTypes

    const material = this.hair.material as THREE.MeshBasicMaterial
    const hairColor = athlete.hairColor ?? HairColorTypes.brown
    material.color = new THREE.Color(gameConfig.hairColors[HairColorTypes[hairColor]]).convertSRGBToLinear()

    this.hair.visible = hairTypesVisible.includes(this.hairType)
    this.hairTail.visible = hairTailTypesVisible.includes(this.hairType)

  }

  public setNotes(): void {

    this.playerNotesManager.setUp()

  }

  /**
   * Aktualizovanie kamera konfigu na zaciatku este
   * @param cameraState - Kamerovy stav
   */
  public updateCameraConfigOnStart(cameraState: CameraStates): void {

    const playerPosition = player.getPosition().clone()

    const cameraData = cameraManager.getTweenSettings()[cameraState]
    if (!cameraData) return

    const cameraDataFromState = (Array.isArray(cameraData)) ? cameraData[0] : cameraData

    // po resete chceme resetnut nastavenia na povodne
    if (this.oldPlayerPos[cameraState]) {

      cameraDataFromState.startPosition.sub(this.oldPlayerPos[cameraState] as THREE.Vector3)
      cameraDataFromState.endPosition.sub(this.oldPlayerPos[cameraState] as THREE.Vector3)

    }
    this.oldPlayerPos[cameraState] = playerPosition.clone()

    cameraDataFromState.startPosition.add(playerPosition)
    cameraDataFromState.endPosition.add(playerPosition)

  }

  /**
   * Konecna akcia pre hraca
   */
  public finishAction(): void {

    // najskor musime ukoncit moznost pohybovych animacii
    this.activeUpdatingMovementAnimations = false

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

  }

  /**
   * changes config of camera
   * @param idealOffset - ideal shift of camera from player
   * @param idealLookAt - ideal place for camera to look at
   * @param coefSize - how fast should camera move (0-1)
   * @param changeLerp - how fast changes should be applied (0-1)
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number
  ): void {

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp
    )

  }

  /**
   * nastavime camera settings podla game configu
   *
   * @param lerpSize - volitelny iny lerp ako v game configu
   */
  public setGameCameraSettings(lerpSize = gameConfig.cameraConfig.changeLerp): void {

    this.changeCameraSettings(
      gameConfig.cameraConfig.idealOffset,
      gameConfig.cameraConfig.idealLookAt,
      gameConfig.cameraConfig.coefSize,
      lerpSize
    )

  }

  /**
   * Spravanie pri dosiahnuti ciela
   * @param actualPosition - Aktualna pozicia v metroch
   * @param lastPosition - Posledna pozicia v metroch
   * @param finishPosition - Pozicia ciela v metroch
   */
  public finishReached(actualPosition: number, lastPosition: number, finishPosition: number): void {

    super.finishReached(actualPosition, lastPosition, finishPosition)
    uiState().blueBoxTextType = BlueBoxTextType.hidden
    textMessageState().showTogo = false

    playersManager.setPlayerResults(this.finalTime)

    disciplinePhasesManager.phaseRunning.finishPhase()

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param actualPhase - Aktualna faza
   * @param inTop3 - Ci je hrac v top 3
   */
  public updateBeforePhysics(actualPhase: DisciplinePhases, inTop3 = false): void {

    this.playerNotesManager.update()
    super.updateBeforePhysics(actualPhase, inTop3)

    if (this.worldEnvLinesManager.isLungeEnabled()) {

      if (!disciplinePhasesManager.phaseRunning.speedLockActive) {

        trainingTasks.saveRunQuality()
        disciplinePhasesManager.phaseRunning.activateSpeedLock()

      }

      if (gameConfig.autoMove.isEnabled) {

        player.startLunge()

      }

    }
    if (this.helperObjectPos === undefined) {

      this.helperObjectPos = this.worldEnvLinesManager.getObject3d().position.clone()

    }
    this.helperObjectPos.lerp(this.worldEnvLinesManager.getObject3d().position, 0.5)
    this.helperObject.position.copy(this.helperObjectPos)

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   */
  public updateAfterPhysics(): void {

    super.updateAfterPhysics()

    this.playerNotesManager.renderLabels()

  }

  /**
   * Aktivacia speed lock-u
   */
  public activateSpeedLock(): void {

    super.activateSpeedLock()

    lapPositionState().showPosition = false
    player.playerNotesManager.setVisibilityOfAll(false)
    this.changeSpeedLockSpeed(0.2)
    tutorialState().showInstructionBox = false
    gamePhaseState().showBar = false
    inputsState().isVisible = false
    disciplinePhasesManager.phaseRunning.showLungeInfo = true

    const { cameraOffset, cameraTransitionDuration } = lungeConfig
    const cameraOffsetTransition = player.worldEnvLinesManager.actualIdealOffset?.clone() || new THREE.Vector3()
    const pathCoef = this.worldEnvLinesManager.pathIndex <= 4 ? -1 : 1

    cameraManager.setTLookAtCoef(1)

    gsap.to(cameraOffsetTransition, {
      duration: cameraTransitionDuration,
      x: cameraOffset.x * pathCoef,
      y: cameraOffset.y,
      z: cameraOffset.z,
      onUpdate: () => {

        cameraManager.changeIdeals(cameraOffsetTransition)

      },
      onComplete: () => {

        const blueBoxType = modes.isTutorial() ? BlueBoxTextType.hidden : BlueBoxTextType.lunge
        uiState().blueBoxTextType = blueBoxType
        this.speedLockCameraTransformationComplete = true
        this.changeSpeedLockSpeed(1)
        disciplinePhasesManager.phaseRunning.cameraFromSide = true

      }
    })

  }

  /**
   * Deaktivovanie spomalenia
   */
  public deactivateSpeedLock(): void {

    super.deactivateSpeedLock()

    this.speedLockCameraTransformationComplete = false

  }

  /**
   * Zmena speedLockCoef a rychlosti animacii a casu
   * @param speedLockCoef - number
   */
  private changeSpeedLockSpeed(speedLockCoef: number): void {

    console.log('SPEED LOCK COEF -> ', speedLockCoef)
    disciplinePhasesManager.phaseRunning.speedLockCoef = speedLockCoef
    timeManager.changeTimeSpeed(disciplinePhasesManager.phaseRunning.speedLockCoef)
    player.animationsManager.setSpeed(disciplinePhasesManager.phaseRunning.speedLockCoef)
    opponentsManager.getOpponents().forEach(opponent => {

      opponent.animationsManager.setSpeed(disciplinePhasesManager.phaseRunning.speedLockCoef)

    })

  }

  /**
   * Zapnutie lunge-u
   */
  public startLunge(): void {

    // aby sa nespustalo viackrat a aby to bolo v zone, kedy to je mozne dat
    if (
      this.lungeState !== LungeStates.none ||
      !this.worldEnvLinesManager?.isLungeEnabled() ||
      !this.speedLockCameraTransformationComplete
    ) return

    super.startLunge()
    uiState().blueBoxTextType = BlueBoxTextType.hidden
    tutorialFlow.eventActionTrigger(TutorialEventType.autoLunge)

  }


  /**
   * Spracovanie lunge-u pre hraca
   */
  public handleLunge(): void {

    this.startLunge()

  }

  /**
   * Resetovanie
   */
  public reset(): void {

    super.reset()
    this.playerNotesManager.reset()
    this.helperObjectPos = undefined

  }

}

export const player = new Player('')
