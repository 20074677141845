import type {
  PlayerStates,
  StartPositionsDataObject
} from '@/app/types'
import {
  minigameConfig,
  modes,
  playersManager,
  type PlayerInfo,
  type TournamentDataFromResultsRequest
} from '@powerplay/core-minigames'
import { player } from '../player'
import { Opponent } from './Opponent'
import { gameConfig } from '@/app/config'
import { fakeOpponents } from './FakeOpponents'

/** Trieda pre spravu protihracov */
export class OpponentsManager {

  /** Pole superov */
  private opponents: Opponent[] = []

  /** pocitadlo framov */
  private frameCounter = 0

  /** true ak v danom mode su opponenti */
  public isActive = true

  /** aktualna top3 hracov */
  private top3Players: string[] = []

  /** originalne data hracov */
  private originalPlayersData: PlayerInfo[] = []

  /**
   * Vrati pocet superov
   * @returns Pocet superov
   */
  public getOpponentsCount(): number {

    return this.opponents.length

  }

  /**
   * Vytvorenie superov
   */
  public create(startData: StartPositionsDataObject): void {

    // nastavime si, kedy su superi neaktivni
    if (modes.isTrainingMode()) {

      this.isActive = false

    }

    if (!this.isActive) return

    const opponentsInfo = playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= gameConfig.numberOfOpponents

    })
    opponentsInfo.forEach((playerInfo: PlayerInfo, index: number) => {

      const opponentInfo = playersManager.getPlayerById(playerInfo.uuid)
      if ((modes.isDailyLeague() || modes.isTournament()) && opponentInfo) {

        opponentInfo.attribute.total = playersManager.getPlayer().attribute.total

      }

      this.opponents[index] = new Opponent(playerInfo.uuid, index + 1)
      this.opponents[index].create(startData[playerInfo.uuid])

    })

  }


  /**
   * Prepisanie dat superov
   */
  public rewriteOpponentsData(): void {

    if (!modes.isTutorial() && !modes.isTrainingMode() && !modes.isDailyLeague() && !modes.isTournament()) return

    this.setFakeData()

  }

  /**
   * Vratenie fake dat hracov
   */
  public setFakeData(): void {

    if (modes.isDailyLeague() || modes.isTournament()) {

      let i
      for (i = 0; i <= playersManager.players.length - 1; i += 1) {

        this.originalPlayersData[i] = playersManager.players[i]

      }

      for (i = 1; i <= gameConfig.numberOfOpponents; i += 1) {

        playersManager.players[i] = fakeOpponents[i - 1]

      }

    }

  }

  /**
   * Vratenie originalnych dat realnych hracov
   */
  public setOriginalData(): void {

    // pozor, tu menime aj hraca, nielen superov
    playersManager.players = []

    for (let i = 0; i <= this.originalPlayersData.length - 1; i += 1) {

      playersManager.players[i] = this.originalPlayersData[i]

      const resultArr = playersManager.players[i].resultsArr
      if (resultArr && resultArr[0] && resultArr[0].main === -1) {

        resultArr[0].main = minigameConfig.dnsValueAscending

      }

    }

  }

  /**
   * aktualizovanie superov pred fyzikou
   */
  public updateBeforePhysics(): void {

    if (!this.isActive) return

    this.frameCounter += 1

    this.opponents.forEach((opponent) => {

      opponent.updateBeforePhysics(this.frameCounter, this.top3Players.includes(opponent.uuid))

    })

  }

  /**
   * aktualizovanie superov po fyzikou
   */
  public updateAfterPhysics(): void {

    if (!this.isActive) return

    const playersPositions = [{ uuid: player.uuid,
      percent: player.getActualPercentOnPath() }]

    this.opponents.forEach((opponent) => {

      opponent.updateAfterPhysics()
      playersPositions.push({ uuid: opponent.uuid,
        percent: opponent.getActualPercentOnPath() })

    })

    playersPositions.sort((a, b) => {

      if (a.percent < b.percent) return 1
      return -1

    })

    this.top3Players = [playersPositions[0]?.uuid, playersPositions[1]?.uuid, playersPositions[2]?.uuid]

  }

  /**
   * Nastavenie stavu vsetkym superom
   * @param state - stav
   * @param maxRandomDelay - maximalna hodnota randomu delayu od 0
   */
  public setStateToAll(state: PlayerStates, maxRandomDelay = 0): void {

    this.opponents.forEach((opponent) => {

      opponent.setState(state, maxRandomDelay)

    })

  }

  /**
   * Nastavenie pozicie supera na starte
   */
  public setActualPercentOnStart(): void {

    this.opponents.forEach((opponent) => {

      opponent.worldEnvLinesManager.setActualPercentOnStart()

    })

  }

  /**
   * Odstartovanie vsetkych superov
   */
  public start(): void {

    if (!this.isActive) return

    this.frameCounter = 0

    this.opponents.forEach((opponent) => {

      opponent.setDelayedStartFrames()

    })

  }

  /**
   * Spomalovanie pri false starte
   */
  public falseStartSlowDown(): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.falseStartSlowDown()

    })

  }

  /**
   * Aktualizovanie animacii superov
   * @param delta - delta pre animacie
   */
  public updateAnimations(delta: number): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.updateAnimations(delta)

    })

  }

  /**
   * Getter
   * @returns oppontnes
   */
  public getOpponents(): Opponent[] {

    return this.isActive ? this.opponents : []

  }

  /**
   * Getter
   * @returns uuids
   */
  public getOpponentsIds(): string[] {

    const uuids = [] as string[]
    playersManager.players.filter((playerInfo: PlayerInfo) => {

      return !playerInfo.playable

    }).forEach((playerInfo: PlayerInfo) => {

      uuids.push(playerInfo.uuid)

    })
    return uuids

  }

  /**
   * Skipnutie rozbehu
   */
  public skipStartRun(): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.athleteAnimationManager.skipStartRun()

    })

  }

  /**
   * Nastavenie DNF pre vsetkych superov co nepresli cielom
   * @returns
   */
  public setDnfIfNotReachedFinish(): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      if (!opponent.finished) playersManager.setPlayerResultsById(opponent.uuid, minigameConfig.dnfValue)

    })

  }

  /**
   * Nastavenie dat o hracoch
   * @param dataFromCallback - data o hracoch
   */
  public setOpponentsDataFromSaveResults(dataFromCallback: TournamentDataFromResultsRequest): void {

    const newPlayersData: PlayerInfo[] = []
    let index = 0
    dataFromCallback.players.forEach((playerInfo) => {

      playerInfo.attribute = {
        base: 0,
        total: 0
      }
      if (playerInfo.uuid === player.uuid) playerInfo.playable = true
      if (playerInfo.resultsArr && playerInfo.resultsArr[0].main === -1) {

        playerInfo.resultsArr[0].main = minigameConfig.dnsValueAscending

      }

      // musime zarucit, ze hrac bude aj tu prvy
      if (!playerInfo.playable) index += 1
      newPlayersData[playerInfo.playable ? 0 : index] = playerInfo


    })

    playersManager.players = newPlayersData

    // musime este upravit data pre hraca, kedze sa vyssim priradenim dali na DNF
    playersManager.players[0].resultsArr = playersManager.players[0].resultsArrOriginal

  }

  /**
   * Aktualizovanie animacii superov
   */
  public reset(): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.reset()

    })

  }

}

export const opponentsManager = new OpponentsManager()
