import { type MaterialDataObject } from '@powerplay/core-minigames'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {

  [MaterialsNames.athlete]: {
    meshesArray: [
      'body_low', 'f_body_low'
    ],
    textureName: TexturesNames.athleteClothes,
    playerIndex: 0
  },
  [MaterialsNames.athleteOpponent1]: {
    meshesArray: [
      'body_low_opponent_1', 'f_body_low_opponent_1'
    ],
    textureName: TexturesNames.athleteClothesOpponent1,
    playerIndex: 1

  },
  [MaterialsNames.athleteOpponent2]: {
    meshesArray: [
      'body_low_opponent_2', 'f_body_low_opponent_2'
    ],
    textureName: TexturesNames.athleteClothesOpponent2,
    playerIndex: 2

  },
  [MaterialsNames.athleteOpponent3]: {
    meshesArray: [
      'body_low_opponent_3', 'f_body_low_opponent_3'
    ],
    textureName: TexturesNames.athleteClothesOpponent3,
    playerIndex: 3

  },
  [MaterialsNames.athleteOpponent4]: {
    meshesArray: [
      'body_low_opponent_4', 'f_body_low_opponent_4'
    ],
    textureName: TexturesNames.athleteClothesOpponent4,
    playerIndex: 4

  },
  [MaterialsNames.athleteOpponent5]: {
    meshesArray: [
      'body_low_opponent_5', 'f_body_low_opponent_5'
    ],
    textureName: TexturesNames.athleteClothesOpponent5,
    playerIndex: 5

  },
  [MaterialsNames.athleteOpponent6]: {
    meshesArray: [
      'body_low_opponent_6', 'f_body_low_opponent_6'
    ],
    textureName: TexturesNames.athleteClothesOpponent6,
    playerIndex: 6

  },
  [MaterialsNames.athleteOpponent7]: {
    meshesArray: [
      'body_low_opponent_7', 'f_body_low_opponent_7'
    ],
    textureName: TexturesNames.athleteClothesOpponent7,
    playerIndex: 7

  },
  [MaterialsNames.boat]: {
    meshesArray: ['kayak'],
    textureName: TexturesNames.boat,
  },
  [MaterialsNames.boatOpponent1]: {
    meshesArray: ['kayak_opponent_1'],
    textureName: TexturesNames.boatOpponent1,

  },
  [MaterialsNames.boatOpponent2]: {
    meshesArray: ['kayak_opponent_2'],
    textureName: TexturesNames.boatOpponent2,

  },
  [MaterialsNames.boatOpponent3]: {
    meshesArray: ['kayak_opponent_3'],
    textureName: TexturesNames.boatOpponent3,

  },
  [MaterialsNames.boatOpponent4]: {
    meshesArray: ['kayak_opponent_4'],
    textureName: TexturesNames.boatOpponent4,

  },
  [MaterialsNames.boatOpponent5]: {
    meshesArray: ['kayak_opponent_5'],
    textureName: TexturesNames.boatOpponent5,

  },
  [MaterialsNames.boatOpponent6]: {
    meshesArray: ['kayak_opponent_6'],
    textureName: TexturesNames.boatOpponent6,

  },
  [MaterialsNames.boatOpponent7]: {
    meshesArray: ['kayak_opponent_7'],
    textureName: TexturesNames.boatOpponent7,

  },
  [MaterialsNames.athleteHair]: {
    meshesArray: ['hair_low', 'tail_low', 'f_hair_low', 'f_tail_low'],
    textureName: TexturesNames.athleteHair,
    transparent: true
  },
  [MaterialsNames.athleteHairOpponent1]: {
    meshesArray: ['hair_low_opponent_1', 'tail_low_opponent_1', 'f_hair_low_opponent_1', 'f_tail_low_opponent_1'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent2]: {
    meshesArray: ['hair_low_opponent_2', 'tail_low_opponent_2', 'f_hair_low_opponent_2', 'f_tail_low_opponent_2'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent3]: {
    meshesArray: ['hair_low_opponent_3', 'tail_low_opponent_3', 'f_hair_low_opponent_3', 'f_tail_low_opponent_3'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent4]: {
    meshesArray: ['hair_low_opponent_4', 'tail_low_opponent_4', 'f_hair_low_opponent_4', 'f_tail_low_opponent_4'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent5]: {
    meshesArray: ['hair_low_opponent_5', 'tail_low_opponent_5', 'f_hair_low_opponent_5', 'f_tail_low_opponent_5'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent6]: {
    meshesArray: ['hair_low_opponent_6', 'tail_low_opponent_6', 'f_hair_low_opponent_6', 'f_tail_low_opponent_6'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent7]: {
    meshesArray: ['hair_low_opponent_7', 'tail_low_opponent_7', 'f_hair_low_opponent_7', 'f_tail_low_opponent_7'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.audience]: {
    textureName: TexturesNames.audience,
    meshesArray: ['Env_K1_Mesh003_10'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5, // alphatest
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.audienceStand]: {
    textureName: TexturesNames.audienceStand,
    meshesArray: ['Env_K1_Mesh003_14'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5, // alphatest
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.colorGradient]: {
    textureName: TexturesNames.colorGradient,
    meshesArray: ['Env_K1_Mesh003_5'],
    vertexColors: true,
  },
  [MaterialsNames.colorGradientWithoutVC]: {
    textureName: TexturesNames.colorGradient,
    meshesArray: ['env_AddBaloons_K1', 'env_Blimp'],
  },
  [MaterialsNames.concrete]: {
    textureName: TexturesNames.concrete,
    meshesArray: ['Env_K1_Mesh003_6'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.woodFloor]: {
    textureName: TexturesNames.woodFloor,
    meshesArray: ['Env_K1_Mesh003_15'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.screens]: {
    textureName: TexturesNames.screens,
    meshesArray: ['Env_K1_Mesh003_13']
  },
  [MaterialsNames.glassPanel]: {
    textureName: TexturesNames.glassPanel,
    meshesArray: ['Env_K1_Mesh003_8'],
    lightmap: TexturesNames.envLightmap,
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.grass]: {
    textureName: TexturesNames.grass,
    meshesArray: ['Env_K1_Mesh003_2'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.trees]: {
    textureName: TexturesNames.trees,
    meshesArray: ['Env_K1_Mesh003_3'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5, // alphatest
    vertexColors: true,
  },
  [MaterialsNames.metal]: {
    textureName: TexturesNames.metal,
    meshesArray: ['StartLine_Blockers_Mesh006_1', 'StartLine_Mesh004'],
    vertexColors: true,
  },
  [MaterialsNames.panel]: {
    textureName: TexturesNames.panel,
    meshesArray: ['Env_K1_Mesh003_4'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.panelWithoutLM]: {
    textureName: TexturesNames.panel,
    meshesArray: ['StartLine_Blockers_Mesh006'],
    vertexColors: true,
  },
  [MaterialsNames.seats]: {
    textureName: TexturesNames.seats,
    meshesArray: ['Env_K1_Mesh003_12'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
    alpha: 0.5, // alphatest
  },
  [MaterialsNames.stairs]: {
    textureName: TexturesNames.stairs,
    meshesArray: ['Env_K1_Mesh003_11'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.lattice]: {
    textureName: TexturesNames.lattice,
    meshesArray: ['Env_K1_Mesh003_9'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5,
    vertexColors: true,
  },
  [MaterialsNames.latticeWithoutLM]: {
    textureName: TexturesNames.lattice,
    meshesArray: ['StartLine_Mesh004_1'],
    alpha: 0.5,
    vertexColors: true,
  },
  [MaterialsNames.flags]: {
    textureName: TexturesNames.flagsImages,
    meshesArray: ['flags_Flag_Base'],
    lightmap: TexturesNames.flagGradient
  },
  [MaterialsNames.flagsGradient]: {
    textureName: TexturesNames.gradient,
    vertexColors: true,
    transparent: true,
    meshesArray: ['StartFlags_BG'],
  },
  [MaterialsNames.leadIndicator]: {
    textureName: TexturesNames.gradient,
    vertexColors: true,
    transparent: true,
    opacity: 0.7,
    meshesArray: ['env_LeadIndicator_Line', 'env_FinishLine_K1'],
  },
  [MaterialsNames.numbers]: {
    textureName: TexturesNames.numbers,
    vertexColors: true,
    meshesArray: ['Env_K1_Mesh003_1'],
    lightmap: TexturesNames.envLightmap,
  },
  [MaterialsNames.numbersWithoutLM]: {
    textureName: TexturesNames.numbers,
    vertexColors: true,
    meshesArray: ['env_NumberPlatesFinish_K1', 'StartLine_Mesh004_2'],
  },
  [MaterialsNames.onlyVertexColors]: {
    meshesArray: ['Env_K1_Mesh003_7'],
    vertexColors: true,
    lightmap: TexturesNames.envLightmap,
  },
  [MaterialsNames.onlyVertexColorsWithoutLM]: {
    meshesArray: ['env_Buoys_K1'],
    vertexColors: true,
  },
  [MaterialsNames.water]: {
    isDefault: true,
    meshesArray: ['env_Water_K1'],
    color: 0x0398fc,
    transparent: true,
    opacity: 0.5
  },
  [MaterialsNames.skyboxBuilding1]: {
    textureName: TexturesNames.skyboxBuilding1,
    meshesArray: ['Buildings_Mesh_1'],
    alpha: 0.5
  },
  [MaterialsNames.skyboxBuilding2]: {
    textureName: TexturesNames.skyboxBuilding2,
    meshesArray: ['Buildings_Mesh'],
    alpha: 0.5
  },
  [MaterialsNames.missing]: {
    isDefault: true,
    meshesArray: [],
    lightmap: TexturesNames.envLightmap,
    color: 0xFF0000
  },
  [MaterialsNames.noteInner]: {
    textureName: TexturesNames.gradient,
    meshesArray: ['notes_K1_Note_Inner'],
    transparent: true,
  },
  [MaterialsNames.noteOuter]: {
    textureName: TexturesNames.gradient,
    meshesArray: ['notes_K1_Note_Outer'],
    transparent: true,
  },

}
