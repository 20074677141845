import { TexturesNames } from '@/app/types'
import {
  game,
  splashesFragmentShader,
  splashesVertexShader,
  wakeFragmentShader,
  wakeVertexShader,
  waterFragmentShader,
  waterVertexShader
} from '@powerplay/core-minigames'
import { THREE } from '@powerplay/core-minigames'
import { player } from '../athlete/player'
import { gameConfig } from '@/app/config'
import { gameSettingsState } from '@powerplay/core-minigames-ui-ssm'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

/**
 * Trieda pre vodu
 */
export class Water {

  /** Timestampy pre splechot vody */
  private splashesTimestamps: number[] = []

  /** Plane pre vlny */
  private planeWake!: THREE.Mesh

  /** Plane pre povrch vody */
  private planeSurface!: THREE.Mesh

  /** Meshe pre splechot vody */
  private planesSplashes: THREE.Mesh[] = []

  /** material pre porvch vody */
  private materialSurface!: THREE.ShaderMaterial

  /** alternativny material pre vodu */
  private materialSurfaceAlternative!: THREE.MeshBasicMaterial

  /** material pre vlnky pre vodu */
  private materialWake!: THREE.ShaderMaterial

  /** materialy pre splechot vody */
  private materialsSplashes: THREE.ShaderMaterial[] = []

  /** Aktualny index pre splechot */
  private splashesActualIndex = 0

  /** hodiny */
  private clock!: THREE.Clock

  /** Helper pre lerp rychlosti */
  private speedLerpHelper = new THREE.Vector2()

  /** Lerp pre rychlost pre shader */
  private speedLerp = new THREE.Vector2()

  /**
   * Vytvorenie plane-u pre shader pre povrch
   */
  public createShaderPlaneSurface(): void {

    this.materialSurface = new THREE.ShaderMaterial({
      uniforms: {
        uTexture1: {
          value: game.texturesToUse.main.get(TexturesNames.voronoiWater)
        },
        uTime: {
          value: 0
        },
        distanceBias: {
          value: 1
        },
        timeFactor: {
          value: 11// 18
        },
        waterDir: {
          value: new THREE.Vector2(1, 0.5)
        },
        specularTreshold: {
          value: 0.9
        },
        surfaceColor: {
          value: new THREE.Color(0x053e61)
        },
        fresnelColor: {
          value: new THREE.Color(0xccfaff)
        },
        baseColorStr: {
          value: 1
        },
        baseColorPow: {
          value: 1.0
        },
        waterOpacity: {
          value: 0.95
        },
        fresnelOffset: {
          value: 0.9
        },
        fresnelPower: {
          value: 5.0
        }
      },
      vertexShader: waterVertexShader,
      fragmentShader: waterFragmentShader
    })

    this.materialSurfaceAlternative = new THREE.MeshBasicMaterial({
      color: new THREE.Color(0x063D5F).convertSRGBToLinear()
    })

    this.materialSurface.transparent = true
    this.materialSurface.needsUpdate = true
    this.planeSurface = game.getObject3D('env_Water_K1') as THREE.Mesh
    this.planeSurface.material = this.materialSurface
    this.planeSurface.renderOrder = 2
    this.planeSurface.matrixAutoUpdate = true

  }

  /**
   * Vytvorenie plane-u pre shader pre vlny
   */
  public createShaderPlaneWake(): void {

    this.materialWake = new THREE.ShaderMaterial({
      uniforms: {
        uPosition: {
          value: new THREE.Vector3(0.0, 0.0, 0.0)
        },
        uTexture1: {
          value: game.texturesToUse.main.get(TexturesNames.wake)
        },
        uTime: {
          value: 0
        },
        distanceBias: {
          value: 1
        },
        timeFactor: {
          value: 50// 18
        },
        waterDir: {
          value: new THREE.Vector2(1, 0.5)
        },
        specularTreshold: {
          value: 0.9
        },
        surfaceColor: {
          value: new THREE.Color(0xffffff)
        },
        baseColorStr: {
          value: 1
        },
        baseColorPow: {
          value: 1.0
        },
        uSpeed: {
          value: 0.1
        }
      },
      vertexShader: wakeVertexShader,
      fragmentShader: wakeFragmentShader
    })
    this.materialWake.transparent = true
    this.materialWake.blending = THREE.AdditiveBlending
    this.materialWake.needsUpdate = true

    this.planeWake = game.getObject3D('WaterEffects_K1_Wake') as THREE.Mesh
    this.planeWake.material = this.materialWake
    this.planeWake.matrixAutoUpdate = true
    this.planeWake.position.set(0, 0.105, 2.7)
    this.planeWake.renderOrder = 3
    player.athleteObject.add(this.planeWake)

  }

  /**
   * Vytvorenie plane-u pre shader pre splechy
   */
  public createShaderPlaneSplashes(): void {

    const meshSplashes = game.getObject3D('WaterEffects_K1_Splashes') as THREE.Mesh

    for (let i = 0; i <= 1; i += 1) {

      this.materialsSplashes[i] = new THREE.ShaderMaterial({
        uniforms: {
          uTexture1: {
            value: game.texturesToUse.main.get(TexturesNames.wake)
          },
          uTime: {
            value: 0
          },
          distanceBias: {
            value: 1
          },
          timeFactor: {
            value: 11
          },
          waterDir: {
            value: new THREE.Vector2(1, 0.5)
          },
          specularTreshold: {
            value: 0.9
          },
          surfaceColor: {
            value: new THREE.Color(0xffffff)
          },
          baseColorStr: {
            value: 2
          },
          baseColorPow: {
            value: 1.0
          },
          waterOpacity: {
            value: 0.95
          },
          duration: {
            value: 1.4
          }
        },
        vertexShader: splashesVertexShader,
        fragmentShader: splashesFragmentShader
      })
      this.materialsSplashes[i].transparent = true
      this.materialsSplashes[i].blending = THREE.AdditiveBlending
      this.materialsSplashes[i].needsUpdate = true
      this.planesSplashes[i] = i === 0 ? meshSplashes.clone() : meshSplashes
      this.planesSplashes[i].material = this.materialsSplashes[i]
      this.planesSplashes[i].matrixAutoUpdate = true
      game.scene.add(this.planesSplashes[i])
      this.planesSplashes[i].visible = false
      this.planesSplashes[i].renderOrder = 6

    }

  }

  /**
   * Vytvorenie veci pre vodu
   * @param clock - Hodiny
   */
  public create(clock: THREE.Clock): void {

    this.clock = clock

    this.createShaderPlaneSurface()
    this.createShaderPlaneWake()
    this.createShaderPlaneSplashes()

  }

  /**
   * Nastavenie veci pre vodu podla kvality
   * @param quality - Kvalita
   */
  public setSettingsByQuality(quality: number): void {

    // transparentnost
    this.materialSurface.transparent = quality >= gameConfig.waterMinQualities.transparency

    // aky material bude mat voda
    this.planeSurface.material = quality >= gameConfig.waterMinQualities.movement ?
      this.materialSurface :
      this.materialSurfaceAlternative

    // zobrazenie alebo schovanie vlniek
    this.planeWake.visible = quality >= gameConfig.waterMinQualities.wake

    // zobrazenie alebo schovanie splechov
    for (let i = 0; i <= 1; i += 1) {

      this.planesSplashes[i].visible = quality >= gameConfig.waterMinQualities.splashes

    }

  }

  /**
   * Zmena fresnelu v shaderi pre vodu
   */
  public changeShaderSurfaceFresnel(): void {

    this.materialSurface.uniforms.fresnelOffset.value = 0.99
    this.materialSurface.uniforms.fresnelPower.value = 40.0
    this.materialSurface.uniforms.distanceBias.value = 2.0

  }

  /**
   * Zobrazenie splechu na pozicii
   * @param position - Pozicia
   */
  public showSplashes(position: THREE.Vector3): void {

    if (gameSettingsState().graphicsSettings < gameConfig.waterMinQualities.splashes) return

    position.y += 0.15
    this.planesSplashes[this.splashesActualIndex].position.copy(position)
    this.planesSplashes[this.splashesActualIndex].visible = true
    this.planesSplashes[this.splashesActualIndex].rotation.set(0, THREE.MathUtils.randFloat(0, Math.PI * 2), 0)
    this.splashesTimestamps[this.splashesActualIndex] = this.clock.getElapsedTime()

    this.splashesActualIndex += 1
    if (this.splashesActualIndex > 1) this.splashesActualIndex = 0

  }

  /**
   * Akutalizacia shadera vody
   * @param newValue - Hodnota casu
   */
  public update(newValue: number): void {

    const quality = gameSettingsState().graphicsSettings

    // pri kvalite 5+ davame pohyblivu vodu
    if (quality >= gameConfig.waterMinQualities.movement) this.materialSurface.uniforms.uTime.value = newValue

    // pri kvalite 7+ davame aj vlny
    if (quality >= gameConfig.waterMinQualities.wake) {

      this.speedLerpHelper.x = player.speedManager.getActualSpeed() / 7
      this.speedLerp.lerp(this.speedLerpHelper, 0.01)

      this.materialWake.uniforms.uTime.value = newValue * disciplinePhasesManager.phaseRunning.speedLockCoef
      this.materialWake.uniforms.uPosition.value = player.getPosition().clone()
      this.materialWake.uniforms.uSpeed.value = this.speedLerp.x

    }

    if (quality >= gameConfig.waterMinQualities.splashes) {

      for (let i = 0; i <= 1; i += 1) {

        if (this.splashesTimestamps[i] !== undefined) {

          this.materialsSplashes[i].uniforms.uTime.value = newValue - this.splashesTimestamps[i]

        }

      }

    }

  }

}