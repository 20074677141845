import {
  pathAssetsBaseNew,
  pathAssetsGlobal,
  pathAssetsKayak
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { TexturesNames } from '../types'

// Hlavny priecinok s texturami
const texturesDir100m = `${pathAssetsBaseNew}100m/textures/`
const texturesDirKayak = `${pathAssetsKayak}/textures/`
const texturesDirGlobal = `${pathAssetsGlobal}/textures/`

/**
 * Konfig pre textury
 */
export const texturesConfig: LoaderDataTypes = {
  [TexturesNames.athleteRaceWhiteMan]: {
    ext: 'ktx2',
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoMan]: {
    ext: 'ktx2',
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteRaceBlackMan]: {
    ext: 'ktx2',
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteRaceWhiteWoman]: {
    ext: 'ktx2',
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoWoman]: {
    ext: 'ktx2',
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteRaceBlackWoman]: {
    ext: 'ktx2',
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteClothes]: {
    ext: 'ktx2',
    genderActive: true,
    version: 240617,
    femaleVersion: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent1]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240617,
    femaleVersion: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent2]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240617,
    femaleVersion: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent3]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240617,
    femaleVersion: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent4]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240617,
    femaleVersion: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent5]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240617,
    femaleVersion: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent6]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240704,
    femaleVersion: 240704,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent7]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240704,
    femaleVersion: 240704,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.boat]: {
    ext: 'ktx2',
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.boatOpponent1]: {
    ext: 'ktx2',
    opponent: true,
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.boatOpponent2]: {
    ext: 'ktx2',
    opponent: true,
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.boatOpponent3]: {
    ext: 'ktx2',
    opponent: true,
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.boatOpponent4]: {
    ext: 'ktx2',
    opponent: true,
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.boatOpponent5]: {
    ext: 'ktx2',
    opponent: true,
    version: 240617,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.boatOpponent6]: {
    ext: 'ktx2',
    opponent: true,
    version: 240704,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.boatOpponent7]: {
    ext: 'ktx2',
    opponent: true,
    version: 240704,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.athleteHair]: {
    ext: 'ktx2',
    version: 231204,
    dir: `${texturesDirKayak}/athlete/`
  },
  [TexturesNames.audience]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.audienceStand]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.colorGradient]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.concrete]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.glassPanel]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.grass]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.metal]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.panel]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.seats]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.stairs]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.lattice]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.woodFloor]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.screens]: {
    ext: 'ktx2',
    version: 240904,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.flagsImages]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.flagGradient]: {
    ext: 'ktx2',
    returnTextureLightmap: true,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.gradient]: {
    ext: 'ktx2',
    dir: `${texturesDir100m}/env/`
  },
  [TexturesNames.numbers]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.trees]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skybox]: {
    ext: 'ktx2',
    version: 241016,
    dir: `${texturesDirGlobal}/env/`
  },
  // [TexturesNames.skyboxMountain]: {
  //   ext: 'ktx2',
  //   dir: `${texturesDirGlobal}/env/`
  // },
  // [TexturesNames.skyboxClouds]: {
  //   ext: 'ktx2',
  //   dir: `${texturesDirGlobal}/env/`
  // },
  [TexturesNames.skyboxBuilding1]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skyboxBuilding2]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.voronoiWater]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`,
    version: 2408212,
  },
  [TexturesNames.wake]: {
    ext: 'png',
    forcedExt: true,
    dir: `${texturesDirGlobal}/env/`,
    version: 241004,
  },
  [TexturesNames.envLightmap]: {
    ext: 'ktx2',
    dir: `${texturesDirKayak}/env/`,
    version: 2408072,
    returnTextureLightmap: true
  },

}
