import { TexturesNames } from '@/app/types'
import { game } from '@powerplay/core-minigames'
import {
  THREE,
  skyboxFragmentShader,
  skyboxVertexShader
} from '@powerplay/core-minigames'

/**
 * Trieda pre oblohu
 */
export class Skybox {

  /** Material pre oblohu */
  private sphereMaterial!: THREE.ShaderMaterial

  /** Ci je povolene hybanie oblohou */
  public movingEnabled = true

  /**
   * Vytvorenie gule oblohy s texturou cez shader
   */
  private createSphereWithShaderTexture(): void {

    const skyboxTexture = game.texturesToUse.main.get(TexturesNames.skybox)
    if (!skyboxTexture) return
    skyboxTexture.magFilter = THREE.LinearFilter
    skyboxTexture.wrapS = THREE.MirroredRepeatWrapping
    skyboxTexture.wrapT = THREE.MirroredRepeatWrapping
    skyboxTexture.minFilter = THREE.LinearMipmapLinearFilter

    const sphereGeo = new THREE.SphereGeometry(6000, 32 * 2, 16 * 2, 0, Math.PI * 2, Math.PI / 2, Math.PI / 2)
    this.sphereMaterial = new THREE.ShaderMaterial({
      uniforms: {
        uTexture1: {
          value: skyboxTexture
        },
        uTime: {
          value: 0
        },
        sunPosition: {
          value: 0.5
        },
        timeFactor: {
          value: 500
        },
        cloudColor: {
          value: new THREE.Color(0xfdfdfd)
        },
        sunColor: {
          value: new THREE.Color(0xfffef7)
        },
        gradientColor1: {
          value: new THREE.Color(0xc7e8ff)
        },
        gradientColor2: {
          value: new THREE.Color(0x88bbfc)
        }
      },
      vertexShader: skyboxVertexShader,
      fragmentShader: skyboxFragmentShader
    })
    const sphereMesh = new THREE.Mesh(sphereGeo, this.sphereMaterial)
    this.sphereMaterial.side = THREE.DoubleSide
    sphereMesh.rotateX(Math.PI)
    sphereMesh.rotation.y = 3.37
    sphereMesh.position.y -= 890
    sphereMesh.scale.x = -1
    game.scene.add(sphereMesh)

  }

  /**
   * Vytvorenie budov
   */
  private createBuildings(): void {

    const buildingsScale = 4
    const buildings1 = game.getMesh('Buildings_Mesh_1')
    buildings1.scale.set(buildingsScale, buildingsScale, buildingsScale)
    buildings1.position.y -= 10
    buildings1.updateMatrix()

    const buildings2 = game.getMesh('Buildings_Mesh')
    buildings2.scale.set(buildingsScale, buildingsScale, buildingsScale)
    buildings2.position.y -= 10
    buildings2.updateMatrix()

  }

  /**
   * Vytvorenie oblohy
   */
  public create(): void {

    this.createSphereWithShaderTexture()
    this.createBuildings()

  }

  /**
   * Aktualizovanie oblohy
   * @param elapsedTime - ubehnuty cas
   */
  public update(elapsedTime: number): void {

    if (!this.movingEnabled) return

    this.sphereMaterial.uniforms.uTime.value = -elapsedTime

  }

  /**
   * Aktualizovanie pozicie slnka
   * @param newValue - nova hodnota pre slnko
   */
  public updateSunPosition(newValue: number): void {

    this.sphereMaterial.uniforms.sunPosition.value = newValue

  }

}