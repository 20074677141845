import {
  gameConfig,
  velocityConfig
} from '../../config'
import type { Athlete } from './Athlete'
import {
  PlayerStates,
  Sides,
  Tasks
} from '@/app/types'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { trainingTasks } from '@/app/modes/training'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { mainState } from '@/stores'
import { player } from './player'
import { endManager } from '@/app/EndManager'

/**
 * Trieda pre spravu rychlosti hraca
 */
export class AthleteVelocityManager {

  /** Ci su inputy bloknute alebo nie */
  public inputsBlocked = false

  /** hodnoty pre speedbar, pre superov */
  public speedbarValues: number[] = []

  /** Aktualna hodnota indexu pre vytahovanie hodnot z pola hodnot pre speedbar */
  private speedbarValuesIndex = 0

  /** Minimalna idealna hodnota */
  private minIdealValue = 0

  /** pocet framov pod idealom */
  public framesUnderIdeal = 0

  /** spravna strana na kliknutie */
  public activeSide = Sides.LEFT

  /** Stlacena strana */
  private pressedSide: Sides | undefined = undefined

  /** framy intervalu - zacina od 1, aby prvy zaber nebol +1 frame navyse */
  public intervalFrames = -4

  /** frame kedy stlacil input */
  public frameInputPressed = -1

  /** posledna paddle quality pre debug */
  public lastStrokeQuality = 0

  /** frekvencia padlovania */
  public paddleFrequency = velocityConfig.paddleFrequency

  /** ci je velocity pauza */
  public paused = false

  /** quality text */
  public qualityText = ''

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Vratenie minimalnej hodnoty idealu
   * @returns hodnota
   */
  public getMinIdealValue(): number {

    return this.minIdealValue

  }

  /**
   * Kontrola inputov a spravenie veci, co sa maju pri nich vykonat
   */
  public handleInputs(side: Sides | undefined = undefined): void {

    if (
      !this.athlete.speedManager.isActive() ||
      this.inputsBlocked ||
      !this.athlete.playable ||
      disciplinePhasesManager.phaseStart.startable
    ) return

    this.inputsBlocked = true
    this.pressedSide = side
    if (this.activeSide === side) {

      this.frameInputPressed = this.intervalFrames
      const { strokeQualityScore } = velocityConfig
      const strokeQuality = 1 - Math.abs(this.frameInputPressed - this.paddleFrequency / 2) / 25
      const strokeQualityPercent = strokeQuality * 100

      let qualityText = ''
      if (strokeQualityPercent > strokeQualityScore.perfect.min) {

        endManager.perfectImpulses += 1
        qualityText = 'perfect'

      } else if (strokeQualityPercent > strokeQualityScore.excellent.min) {

        qualityText = 'excellent'

      } else if (strokeQualityPercent > strokeQualityScore.good.min) {


        qualityText = 'good'

      } else {

        qualityText = 'poor'

      }
      this.qualityText = qualityText

    }

    player.playerNotesManager.afterInputEffect(side)

  }

  /**
   * Zvysenie hodnoty baru
   */
  private addSpeedBoost(): void {

    let strokeQuality = 0
    const { maxBoost, strokeQualityScore } = velocityConfig

    if (this.athlete.playable) {

      if (!gameConfig.autoMove.isEnabled && this.frameInputPressed < 0) {

        this.paddleFrequency = strokeQualityScore.poor.newFrames
        this.lastStrokeQuality = 0

        console.log(`Player didnt paddle/wrong side, new frequency: ${this.paddleFrequency}`)
        tutorialFlow.managePaddle(this.pressedSide !== undefined, this.activeSide === this.pressedSide)
        trainingTasks.countTaskValue(Tasks.runQuality, 0)
        this.qualityText = 'poor'
        player.playerNotesManager.update()

        return

      }

      strokeQuality = 1 - Math.abs(this.frameInputPressed - this.paddleFrequency / 2) / 25
      if (gameConfig.autoMove.isEnabled) strokeQuality = 1

    } else {

      strokeQuality = this.getStrokeQualityFromArray()

    }
    strokeQuality = Math.max(0, strokeQuality)
    this.lastStrokeQuality = strokeQuality

    const strokeQualityPercent = strokeQuality * 100
    if (this.athlete.playable) {

      const trainingStrokeQuality = 1 - Math.abs(this.frameInputPressed - this.paddleFrequency / 2) / 25
      trainingTasks.countTaskValue(Tasks.runQuality, trainingStrokeQuality)

    }

    if (strokeQualityPercent > strokeQualityScore.perfect.min) {

      this.paddleFrequency = strokeQualityScore.perfect.newFrames
      tutorialFlow.manageGoodPaddle()

    } else if (strokeQualityPercent > strokeQualityScore.excellent.min) {

      this.paddleFrequency = strokeQualityScore.excellent.newFrames
      tutorialFlow.manageGoodPaddle()

    } else if (strokeQualityPercent > strokeQualityScore.good.min) {

      this.paddleFrequency = strokeQualityScore.good.newFrames

    } else {

      this.paddleFrequency = strokeQualityScore.poor.newFrames

    }

    if (this.athlete.playable) {

      console.log(`Player stroke quality: ${strokeQuality
      },paddle frame ${this.frameInputPressed
      }, new frequency: ${this.paddleFrequency}`)
      tutorialFlow.managePaddle(true, this.activeSide === this.pressedSide)

    }

    let speedBoost = maxBoost * strokeQuality
    const athleteSpeed = this.athlete.speedManager.getActualSpeed()
    if (this.athlete.speedManager.topSpeed - athleteSpeed < maxBoost) {

      speedBoost = speedBoost * (this.athlete.speedManager.topSpeed - athleteSpeed) / maxBoost

    }

    this.athlete.speedManager.setActualSpeed(athleteSpeed + speedBoost)

    // if (this.athlete.playable) {

    //   console.log(`hrac stlacil input v ${ this.frameInputPressed }.frame, strokeQuality: ${
    //     strokeQuality}, speed: ${this.athlete.speedManager.getActualSpeed()}`)

    // }
    // console.log(`${this.athlete.uuid} strokeQuality: ${strokeQuality}`)

  }

  /**
   * Aktualizovanie rychlosti
   */
  public update(): void {

    if (this.athlete.finished || this.paused) return
    if (this.intervalFrames >= this.paddleFrequency) {

      if (this.athlete.isState(PlayerStates.run) && !this.athlete.speedLockActive) {

        this.addSpeedBoost()

      }
      this.intervalFrames = 0
      this.frameInputPressed = -1
      this.qualityText = ''
      this.pressedSide = undefined
      this.inputsBlocked = false
      if (this.activeSide === Sides.LEFT) {

        this.activeSide = Sides.RIGHT

      } else {

        this.activeSide = Sides.LEFT

      }

    }
    this.intervalFrames += disciplinePhasesManager.phaseRunning.speedLockCoef

    // UI update - iba pre hraca
    if (!this.athlete.playable) return

    mainState().$patch({
      speed: this.athlete.speedManager.getActualSpeed(),
      strokeQuality: this.lastStrokeQuality
    })

  }

  /**
   * Nastavenie rychlosti z pola
   */
  private getStrokeQualityFromArray(): number {

    const strokeQuality = this.speedbarValues[this.speedbarValuesIndex]

    this.speedbarValuesIndex += 1
    if (this.speedbarValuesIndex >= this.speedbarValues.length) {

      this.speedbarValuesIndex = 0

    }

    return strokeQuality

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.minIdealValue = 0
    this.inputsBlocked = false
    this.speedbarValues = []
    this.speedbarValuesIndex = 0
    this.minIdealValue = 0
    this.framesUnderIdeal = 0
    this.activeSide = Sides.LEFT
    this.intervalFrames = -4
    this.frameInputPressed = -1
    this.lastStrokeQuality = 0
    this.paddleFrequency = velocityConfig.paddleFrequency
    this.qualityText = ''

  }

}
